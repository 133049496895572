<template>
  <div id="list-subscription-form-container" role="alert">
    <div class="field is-grouped button-embedded">
      <div class="control">
        <input class="input" type="text" placeholder="Email Address" aria-label="Email address" v-model="$v.emailAddress.$model" :disabled="loading" @keydown.enter.prevent="subscribe">
      </div>
      <div class="control">
        <button class="button is-primary" aria-label="Submit email" :disabled="loading" @click.prevent="subscribe">
          <i class="fal fa-paper-plane"></i>
        </button>
      </div>
    </div>
    <div class="error-container" v-if="error">
      <b-icon class="error-icon" pack="fas" icon="exclamation-triangle"></b-icon>
      <p class="error-message">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import validationMixin from "../../mixins/validation-support";
import Http from "../../classes/global/Http.js";
import {required, email} from "vuelidate/lib/validators";

export default {
  name: "IterableMultiSubscriptionForm",

  mixins: [validationMixin],

  props: {
    listNames: {
      default: []
    },
    messageNames: {
      default: []
    },
    marketingChannel: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      emailAddress: "",
      loading: false,
      error: undefined
    }
  },

  methods: {
    subscribe() {
      this.$v.$reset();
      this.$v.$touch();
      if (!this.loading) {
        if (this.$v.$invalid) {
          this.error = "Invalid Email Address";
        }
        else {
          this.loading = true;
          this.error = undefined;
          Http.post("/email/multi/subscribe", {
            email: this.emailAddress,
            lists: this.listNames,
            messages: this.messageNames,
            marketingChannel: this.marketingChannel
          })
              .then(result => {
                this.$emit("on-success", this.emailAddress);
              })
              .catch(e => {
                this.errors = e.errors;
                this.$emit("on-error", e);
              })
              .finally(() => {
                this.loading = false
              });
        }
      }
    }
  },

  validations: {
    emailAddress: {
      required,
      email
    }
  },
}
</script>