<template>
    <div class="search-box">
    	<div class="search-field" v-click-outside="close">
	    	<form action="/search" method="get" autocomplete="off" role="search">
		    	<div class="field">
		    		<div class="control has-icons-left has-icons-right">
		    			<input ref="searchInput" aria-label="Search site content" role="searchbox" type="text" class="input" v-model="query" name="q" id="search" :placeholder="placeholderText">
		   				<input type="submit" value="search" hidden/>
              <span class="icon is-small is-left">
		   					<i class="far fa-search"></i>
		  				</span>
		  				<span class="icon is-small is-right is-clear-button" @click="clear" v-if="hasQuery">
		   					<i class="fas fa-times-circle"></i>
		  				</span>
		   			</div>
				</div>
			</form>
			<div class="suggestions" v-if="hasSuggestions">
				<ul>
					<li v-for="suggestion in suggestions">
						<a :href="suggestion.seeMoreUrl" @click="close">
							<i :class="suggestionIcon(suggestion)"></i> {{suggestion.title}}
						</a>
					</li>
				</ul>
			</div>
        <div class="screenreader-only" id="results-suggestions-status" role="status">
          {{accessibilitySearchResultsAvailable}}
        </div>
		</div>
	</div>
</template>

<script>
import debounce from "lodash.debounce";
import vClickOutside from "v-click-outside";

export default {
	name: "SearchBox",
	
	directives: {
    	clickOutside: vClickOutside.directive,
    },
	
	data() {
		return {
			isSearching: false,
			query: null,
			suggestions: [],
		}
	},

  props: {
    placeholderText: { type: String, default: 'Search' },
  },
	
	computed: {
		hasQuery() {
			return this.query && this.query.length > 0;
		},
		
		hasSuggestions() {
			return this.suggestions.length > 0;
		},

    accessibilitySearchResultsAvailable() {
      return this.suggestions.length > 0 ? "Suggestions available" : '';
    }
	},
	
	methods: {
		
		clear() {
			this.query = null;
			this.$refs.searchInput.focus();
		},

    close(event) {
      // Yeah, this is unfortunate ..
      if (event.target != null && event.target.parentElement != null && event.target.parentElement.className != null) {
        const isClearButton = event.target.parentElement.className.includes("is-clear-button");

        if(!isClearButton) {
          this.isSearching = false;
          this.query = null;
        }
      }
      else {
        this.isSearching = false;
        this.query = null;
      }
    },

		suggestionIcon(suggestion) {
        	let icon = "book";
        	
        	if(suggestion.title === "Products") {
        		icon = "capsules";
        	} else if(suggestion.title === "Articles") {
        		icon = "newspaper";
        	} else if(suggestion.title === "Videos") {
        		icon = "video";
        	} else if(suggestion.title === "Ingredients") {
        		icon = "mortar-pestle";
        	}
        	
        	return "far fa-" + icon;
        },
	},
	
	watch: {
		query: debounce(function (query) {
			var self = this;
			
			// Clear out existing suggestions
			while(self.suggestions.length > 0) {
				self.suggestions.pop();
			}
			
			if(query && query.length > 0) {
				axios.get("/search/suggestions", {params: {q: query}})
					.then(function (response) {
						response.data.forEach(function (suggestion) {
							if(suggestion.empty === false) {
								// Add the see more url
								suggestion.seeMoreUrl = "/search?q=" + query + "#" + suggestion.title.toLowerCase();
								
								// Add to suggestions
								self.suggestions.push(suggestion);
							}
						});
					})
					.catch(function (error) {
						// TODO - handle
					});
			} else {
				this.suggestions = [];
			}
		}, 500)
	},
	
}
</script>