<template>
  <div class="is-hidden-print">
    <hero-modal
        v-if="modalEnabled && showModal"
        :background-color="modalBackgroundColor"
        :icon="modalIcon"
        @close="showModal = false"
        v-cloak>
      <h1 class="title is-1">{{ modalTitle }}</h1>

      <slot name="modal-content"></slot>

      <div>
        <b-button type="is-black" @click="showModal = false">Close</b-button>
      </div>
    </hero-modal>

    <div id="global-banner"
         :class="{'is-clickable': modalEnabled}"
         @click="toggleModal"
         v-if="showBanner">

      <div class="global-message">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import HeroModal from "./features/HeroModal.vue";

export default {
  name: 'GlobalBanner',

  components: { HeroModal },

  props: {
    enabled: {
      type: Boolean,
      default: false
    },

    modalEnabled: {
      type: Boolean,
      default: false
    },

    restrictedCountries: {
      type: Array,
      default: function () { return [] }
    },

    restrictedPaths: {
      type: Array,
      default: function () { return [] }
    },

    modalTitle: {
      type: String,
      default: ""
    },

    modalIcon:  {
      type: String,
      default: ""
    },

    modalBackgroundColor: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      showModal: false
    }
  },

  computed: {
    showBanner() {
      if (!this.enabled) {
        return false;
      }

      const enabledInCountry = (this.restrictedCountries || []).length === 0 || this.restrictedCountries.includes(defaultCountry);
      if (!enabledInCountry) {
        return false;
      }

      return this.restrictedPaths?.length === 0
          || this.restrictedPaths.filter((uri) => window.location.pathname.match("^" + uri.replace(/\*/g, ".*?") + "$")).length > 0;
    }
  },

  methods: {
    toggleModal() {
      if (this.modalEnabled) {
        this.showModal = !this.showModal;
      }
    }
  }

}
</script>

<style lang="scss" scoped>

#global-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: #fcfcfc !important;
  background-color: #191919;

  .icon {
    color: #FF9E1B
  }

  .icon, .emoji {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .global-message {
    align-self: center;
    text-align: center;

    a {
      text-decoration: underline;
    }

    a:not(:hover) {
      color: #fcfcfc !important;
    }
  }
}

</style>