<template>
  <div class="review-popup">
    <b-modal :active="showModal"
             :auto-focus="false"
             :trap-focus="false"
             :can-cancel="['outside']"
             scroll="keep"
             :on-cancel="close"
             custom-class="popup-modal" v-if="prompting && productsOrReviewProvided">
      <div class="modal-card modal-div">
      <div class="content-div low-content">

        <!-- prompt to review div -->
        <div class="columns is-mobile">
          <div class="column is-2 is-flex is-justify-content-center is-align-content-center is-flex-wrap-wrap">
            <b-button @click="closePrompt"
                      icon-pack="far"
                      icon-left="times"
                      class="content-close"
                      tabindex="0"
                      :aria-label="`Close ${title} dialog`">
            </b-button>
          </div>
          <div class="column is-10">
            <div class="columns is-mobile">
              <div class="column is-9 prompt-text">
                <div>How was your experience?</div>
                <div class="write-review" ><a href="#" @click="writeReview">Write a review</a></div>
              </div>
              <div class="column is-3">
                <div v-if="currentProduct.primaryImageUrl" class="prompt-image">
                  <img class="bordered-image" :src="currentProduct.primaryImageUrl" aria-labelledby="review-product-name" alt="image of product"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
    </b-modal>

    <!-- Product review popup modal -->
    <popup-modal v-else-if="productsOrReviewProvided" :show-modal="showModal"
                  :title="reviewing ? 'Product Review' : 'Thank you!'"
                 @closed="close"
                 aria-describedby="review-product-description review-product-name"
                 auto-focus
                 trap-focus
                 v-cloak>

      <div class="popup-modal-content product-review">
        <template v-if="currentProduct && reviewing">
          <p id="review-product-name" class="product-name">{{currentProduct.name}}</p>
          <p id="review-product-description" class="content-subtitle">How would you rate this purchase?</p>
          <div class="columns is-mobile product-div">
            <div v-if="currentProduct.primaryImageUrl" class="column is-6">
              <div class="product-image">
                <img class="bordered-image" :src="currentProduct.primaryImageUrl" aria-labelledby="review-product-name" alt=""/>
              </div>
            </div>
            <div class="column is-6">
              <fieldify :errors="errors" error-prefix="Review rating" label-id="field-review-rating" error-field="review.rating">
                <review-stars class="star-rating"
                              :editable="true"
                              aria-errormessage="error-field-review-rating"
                              v-model="$v.review.rating.$model">
                </review-stars>
              </fieldify>
            </div>
          </div>
          <p class="content-subtitle">What was your reason for your rating?</p>
          <fieldify :errors="errors" error-prefix="Review title" label-id="field-review-title" error-field="review.title">
            <b-input v-model="$v.review.subject.$model"
                     size="is-large"
                     placeholder="Review Title"
                     aria-label="Review Title"
                     aria-required="true"
                     aria-errormessage="error-field-review-title"
                     :aria-invalid="$v.review.subject.$invalid"
                     tabindex="0">
            </b-input>
          </fieldify>
          <fieldify :errors="errors" error-prefix="Review description" label-id="field-review-description" error-field="review.description">
            <b-input v-model="$v.review.body.$model"
                     type="textarea"
                     size="is-large"
                     placeholder="Review Description"
                     aria-label="Review Description"
                     aria-required="true"
                     aria-multiline="true"
                     aria-errormessage="error-field-review-body"
                     :aria-invalid="$v.review.body.$invalid"
                     tabindex="0">
            </b-input>
          </fieldify>
          <div class="control">
            <div v-if="this.review.productId">
              <div class="columns">
                <div class="column is-6">
                  <b-button @click="deleteReview" class="is-large" :disabled="reviewSaving" :loading="reviewSaving" expanded>Delete</b-button>
                </div>
                <div class="column is-6">
                  <b-button @click="submitReview" class="is-black is-large" :disabled="reviewSaving" :loading="reviewSaving" expanded>Update</b-button>
                </div>
              </div>
            </div>
            <b-button v-else @click="submitReview" class="is-black is-large" :disabled="reviewSaving" :loading="reviewSaving" expanded>Submit</b-button>
          </div>
        </template>
        <template v-else-if="hasReviewedProducts">
          <p class="content-general">We appreciate your feedback</p>
          <template v-if="hasUnreviewedProducts">
            <p class="content-subtitle">Want to review more of your purchases?</p>
            <div class="control">
              <b-button @click="reviewNextProduct" class="is-black" tabindex="0" expanded>Keep reviewing!</b-button>
            </div>
          </template>
          <div class="control">
            <b-button @click="close" :class="{'is-white': hasUnreviewedProducts, 'is-black': !hasUnreviewedProducts}" tabindex="0" expanded>Close</b-button>
          </div>
        </template>
      </div>
    </popup-modal>
  </div>
</template>

<script>
import validationMixin from "../../mixins/validation-support";
import PopupModal from "../layouts/PopupModal.vue";
import Fieldify from "../Fieldify.vue";
import ReviewStars from "./ReviewStars.vue";
import Http from "../../classes/global/Http.js";
import {between, minLength, required} from "vuelidate/lib/validators";
import Cookies from "js-cookie";
export default {
  name: "ReviewPopup",

  mixins: [validationMixin],

  components: {PopupModal, Fieldify, ReviewStars},

  props: {
    productsToReview: {
      type: Array,
      default: () => []
    },
    promptFirst: {default: false},
  },

  data(){
    return {
      errors: {},
      reviewedProducts: [],
      reviewing: true,
      reviewSaving: false,
      review: {
        productId: null, //non-null indicates a review was passed to be edited
        subject: "",
        body: "",
        rating: null,
        imageUrl: null
      },
      showModal: false,
      promptClosed: false,
    }
  },

  validations: {
    review: {
      subject: {
        required,
        minLength: minLength(1)
      },
      body: {
        required,
        minLength: minLength(1)
      },
      rating: {
        required,
        between: between(1, 5)
      }
    }
  },

  methods:{
    close(){
      Cookies.set("hide-review-popup", "1", {expires: 3});
      this.showModal = false;
    },

    writeReview(){
      //close prompt, user chose to write a review
      this.promptClosed = true;
    },
    open(){
      this.reviewing = true;
      this.reviewedProducts = [];
      this.clearReview();
      this.showModal = true;
    },

    reviewNextProduct() {
      this.reviewing = true;
    },

    setReview(review){
      //called from parent component
      this.review = review;
    },

    closePrompt(){
      this.close();
      this.promptClosed = true;
    },

    deleteReview() {
      this.reviewSaving = true;
      Http.delete(`/account/data/reviews/${this.review.productId}`)
          .finally(() => {

            //show close button
            this.reviewedProducts.push(this.review);

            this.reviewSaving = false;
            this.clearReview();
            this.reviewing = false;
            this.$emit("reviewed");
          });
    },

    clearReview() {
      this.review.productId = null;
      this.review.rating = null;
      this.review.subject = "";
      this.review.body = "";
      this.review.imageUrl = null;
    },

    submitReview(){
      this.$v.review.$reset();
      this.$v.review.$touch();
      if (!this.$v.review.$invalid && !this.reviewSaving) {

        let postData = this.review;
        postData.productId = this.currentProduct.id;

        this.reviewSaving = true;
        Http.post(`/account/data/reviews`, postData, null,
            _ => {
              this.$v.review.$reset();
              this.reviewedProducts.push(this.currentProduct);
              this.reviewSaving = false;
              this.clearReview();
              this.reviewing = false;
              this.$emit("reviewed");
            },
            _ => {
              this.reviewSaving = false;
            });
      }
    },
  },

  computed:{

    prompting(){
      return (this.promptFirst && !this.promptClosed);
    },

    productsOrReviewProvided(){
      //make sure there were products to be reviewed, or a review is being edited
      return (this.productsToReview && this.productsToReview.length > 0) || this.review.productId;
    },

    hasUnreviewedProducts() {
      return this.reviewedProducts.length < this.productsToReview.length;
    },

    hasReviewedProducts() {
      return this.reviewedProducts?.length > 0;
    },

    currentProduct() {
        for (let i = 0; i < this.productsToReview.length; i++) {
          if (!this.reviewedProducts.includes(this.productsToReview[i])) {
            return this.productsToReview[i];
          }
        }
        return null; // Return null if all products have been reviewed
      }
    }
}
</script>

<style lang="scss" scoped="scoped">
.review-popup {
  z-index: 1000;
  position: absolute;
  overflow-y:auto;
  
  .prompt-text {
    font-weight: 500;

    .write-review {
      text-decoration: underline;
    }

    @media (max-width: 767px) {
      font-size:1.14rem;
    }
  }

  .prompt-image {
    height: 4rem;
  }

  .product-name {
    font-size: 1.14rem;
    font-weight: 700;
  }

  .star-rating {
    font-size: 1.5rem;
    padding-top: 4rem;
  }


  .bordered-image {
    width: 100%;
    height: 100%;
    background: #FCFCFC;
    border: 1px solid #EBEBEB;
    border-radius: 6px;
  }

  .product-review {
    input {
      resize: none;
    }

    .review-description-input {
      resize: none;
      width: 100%;
      height: 100%;
    }

    .star-rating {
      font-size: 1.5rem;
      padding-top: 4rem;
    }

    .product-name {
      font-weight: 700;
      font-size: 1.14rem;
    }

    .product-div {
      margin-top: 1rem;
    }

    .product-image {
      height: 11rem;
    }

    .error-message {
      margin-top: 1rem;
      margin-bottom: 0.2rem;
      color: red;
    }
  }

  .low-content {
    background-color: white;
    border-radius: 8px;
    border: solid 1px gray;
    width: 100%;
    padding: 1.8rem;

    @media (max-width: 767px) {
      padding: 1.5rem;
      width: 94%;
      height: auto;
      position: fixed;
      top: 85%;
      left: 50%;
      -ms-transform: translate(-50%, 0%);
      transform: translate(-50%, 0%);
    }
  }
}
</style>