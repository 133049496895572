<template>
  <b-modal
    :active="showModal"
    :auto-focus="autoFocus"
    :trap-focus="trapFocus"
    :aria-role="isAlert ? 'alertdialog' : 'dialog'"
    aria-labelledby="modal-title"
    :can-cancel="false"
    :custom-class="customClass"
    scroll="keep"
    aria-modal
    has-modal-card>

    <div class="modal-card modal-div">
      <div class="content-div">
        <div class="columns is-mobile">
          <div class="column is-10 content-title">
            <p class="content-title" id="modal-title">{{ title }}</p>
          </div>
          <div class="column is-2">
            <b-button @click="close"
                      icon-pack="far"
                      icon-left="times"
                      class="content-close"
                      tabindex="0"
                      :aria-label="`Close ${title} dialog`">
            </b-button>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "PopupModal",

  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isAlert: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    trapFocus: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: 'popup-modal'
    }
  },

  methods: {
    close() {
      this.$emit('closed');
    }
  }
};

</script>
